<template lang="pug">
div
  modal.reports-modal(
    v-if="modalVisible",
    @close="modalVisible = false",
    :title="modalTitle",
    :scrollable="false"
  )
    //- :size="85%",
    component(
      :is="inmodal",
      :modalProps="modalData",
      @cancel-modal="modalVisible = false"
    )
  section.section
    h1.title.is-3
      i.icon-Reports
      | Reports
    .divider
      .columns
        .column
          .filter
            #sectors.control
              label.label Region
              multi-select(
                :options="getSectors",
                track-by="id",
                label="name",
                placeholder="Regions",
                v-model="selectedSectors",
                :multiple="true",
                group-label="name",
                group-values="subs",
                :showLabels="false",
                @input="isChangedSectorsList"
              )
          .filter
            #workers.control
              label.label Worker
              multi-select(
                :options="agencyWorkers",
                track-by="id",
                label="name",
                :close-on-select="true",
                :searchable="true",
                v-model="selectedWorkers",
                placeholder="Workers",
                :multiple="true",
                :showLabels="false",
                :internal-search="false",
                @search-change="asyncFindWorker"
              )

        .breaker-line
        .column
          .filter
            #clients.control
              label.label Client
              multi-select(
                :options="filterClients",
                track-by="id",
                label="name",
                placeholder="Clients",
                :value="selectedClients",
                :loading="isLoadingLocations",
                :multiple="true",
                :showLabels="false",
                ref="clientMultiselect",
                @input="isChangedClientsList"
              )
          .filter(v-if="$can('view-client-activity-report')")
            #statuses.control
              label.label Status
              .status
                multi-select(
                  :options="optionsStatus",
                  track-by="id",
                  label="label",
                  :allow-empty="false",
                  v-model="selectedStatus",
                  placeholder="Statuses",
                  :showLabels="false"
                )
                span.info(
                  v-tooltip="{ content: `The status filter is only applicable to the 'Client Activity Report'.`, classes: 'report-status-tooltip' }"
                )
                  span.fa.fa-info.popup
        .column
          .filter
            #locations.control
              label.label Ward
              multi-select(
                :options="filterLocation",
                :options-limit="999",
                select-label="",
                track-by="id",
                group-label="client",
                group-values="subs",
                label="name",
                :loading="isLoadingLocations",
                :close-on-select="false",
                :multiple="true",
                v-model="selectedLocation",
                placeholder="Wards"
              )
                span(slot="noResult") Nothing found.
                //- @input="isChangedCcList"
                //- @select="fireSearchMultiple",
                //- @remove="removeFilter"
        .column
          .filter
            #categories.control
              label.label Category
              multi-select(
                :options="filteredCategories",
                track-by="id",
                label="name",
                v-model="selectedCategories",
                placeholder="Categories",
                :multiple="true",
                :showLabels="false"
              )
        .column
          .filter
            #subcategories.control
              label.label Subcategory
              .subcat
                multi-select(
                  :options="filteredSubcategories",
                  track-by="id",
                  label="name",
                  v-model="selectedSubcategories",
                  placeholder="Subcategories",
                  group-label="name",
                  group-values="subcategories",
                  :multiple="true",
                  :showLabels="false"
                )
                span.info(
                  v-tooltip="{ content: `Subcategory filter is only applicable to the 'Holiday Balances Report'.`, classes: 'report-status-tooltip' }"
                )
                  span.fa.fa-info.popup
    .columns
      .column
        .field
          p.control
            input.input.has-icon-right(
              type="text",
              placeholder="Filter reports",
              v-model="search"
            )
            i.fa.fa-search
      .right-side(data-filter="date")
        .column
          label.label
            img(src="../../assets/images/comps/shift_schedule/calendar.svg")
          .control
            multi-select#ms(
              :options="optionsInterval",
              placeholder="Choose interval",
              :showLabels="false",
              v-model="selectedInterval",
              @input="getDates"
            )
        .column
          label.label FROM
          div(@click="selectedInterval = ''")
            date-picker(:date="startDate", :option="dateTimeOption")
          label.label TO
          div(@click="selectedInterval = ''")
            date-picker(:date="endDate", :option="dateTimeOption")
    table-reports(
      :getReports="filterReports",
      :isPreviewingReport="isPreviewingReport",
      @preview="preview",
      @download="download",
      :search="search"
    )
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import calendarOptions from "../common/calendarOptions.js";
import MultiSelect from "vue-multiselect";
import TableReports from "./TableReports.vue";
import ReportPreview from "../in-modal/reports/ReportPreview.vue";
import moment from "moment";
import _ from "underscore";
import { ref } from "vue";

export default {
  name: "Reports",

  components: {
    MultiSelect,
    TableReports,
    ReportPreview,
  },
  setup() {
    const selectedWorkers = ref([]);
    return { selectedWorkers };
  },
  data() {
    return {
      dateTimeOption: null,
      MultiShift: null,

      modalVisible: false,
      inmodal: "report-preview",

      startDate: { time: "" },
      endDate: { time: "" },
      isEmpty: false,
      error: "These fields are required!",

      multiselectLabel: "",

      selectedSectors: [],

      optionsClients: [],
      selectedClients: [],

      selectedLocation: [],
      isLoadingLocations: false,

      optionsCategories: [],
      selectedCategories: [],

      selectedSubcategories: [],

      // selectedWorkers: [],

      search: "",
      isPreviewingReport: false,

      optionsInterval: ["This week", "Last week"],
      selectedInterval: "",

      reportsHardcodedPerm: {
        "general-client-schedule-report": null,
        "unbilled-shifts-report": "view-unbilled-shifts-report", // Probably not used perm
        "unprocessed-shifts-report": null,
        "client-assigned-report": "view-client-assigned-report",
        // R15 view-client-activity-report
        // R16 view-client-unassigned-activity-report
      },
      // localReports: [],
      optionsStatus: [
        {
          id: 0, // This will fallthrough
          label: "All",
          backColor: "white",
        },
        {
          id: 1,
          label: "Active",
          backColor: "#23d160",
        },
        {
          id: 2,
          label: "Review",
          color: "black",
          backColor: "#ffdb4a",
        },
        {
          id: 3,
          label: "Inactive",
          backColor: "#ff2b56",
        },
        {
          id: 4,
          label: "Approval",
          color: "black",
          backColor: "#a8bc46",
        },
      ],
      selectedStatus: {},
      // TODO [Back] - Move list of keys to backend so front is not hardcoded
      ignoredTimeIntervalKeys: [
        "client-activity-report",
        "temp-data-last-worked-report",
        "holiday-balances-report",
        "temp-data-increment-counter-report",
        "temp-data-compliance-report",
        "temp-holiday-balance-report",
        "annual-leave-accumulation-report",
      ],
      // TODO [Back] - Move list of keys to backend so front is not hardcoded
      ignoredSubcatKeys: [
        "holiday-balances-report",
        "management-shift-status-report",
        "cancelled-shifts-report",
      ],
      // TODO [Back] - Move list of keys to backend so front is not hardcoded
      oneClientPerms: [
        "view-client-assigned-report",
        "view-client-activity-report",
        "view-client-unassigned-activity-report",
      ],
      // TODO [Back] - Move list of keys to backend so front is not hardcoded
      oneWorkersPerms: ["annual-leave-accumulation-report"],
      // TODO [Back] - Move list of keys to backend so front is not hardcoded
      reportCanUseSelectWorkerFilter: [
        "expenses-per-person-report",
        "general-client-schedule-report",
        "unbilled-shifts-report",
        "unprocessed-shifts-report",
        "temp-data-last-worked-report",
        "holiday-balances-report",
        "temp-data-increment-counter-report",
        "shift-analysis-report",
        "temp-data-compliance-report",
        "cancelled-shifts-report",
        "temp-holiday-balance-report",
        "unprocessed-premium-holiday-pay-report",
        "annual-leave-accumulation-report",
      ],
    };
  },
  computed: {
    ...mapGetters({
      getSectors: "locationClientManagement/getSectors", // Final
      clients: "getAllClients",
      getPreviewData: "reports/getPreviewData",
      getReports: "reports/getReports",
      getClientsWithLocations: "getClientsWithLocations",
      agencyWorkers: "getAgencyWorkers",
    }),
    filterReports() {
      return this.getReports.map((el, index) => {
        el.index = index;
        return el;
      });
    },
    filterClients() {
      // logic to return clients for multiple selection of Sectors
      // Say this is the filtered result for now
      const values = this.selectedSectors;
      if (values.length) {
        return values.reduce((acc, next) => {
          // Find clients for current sector iteration
          for (const sectorGroup of this.getSectors) {
            const sectors = sectorGroup.subs;
            const found = _.findWhere(sectors, { id: next.id });
            if (found) {
              // Push its clients into the result
              acc.push(...found.clients);
            }
          }
          return acc;
        }, []);
      }
      // Otherwise, if non of Sectors is selected
      // give back all clients (from getter)!
      return this.clients;
    },
    filterLocation() {
      if (Array.isArray(this.selectedClients) && this.selectedClients.length) {
        return this.getClientsWithLocations.filter((cc) => {
          const clientNames = this.selectedClients.map((client) => client.name);
          return clientNames.includes(cc.client);
        });
      }
      // return this.getClientsWithLocations
      return [];
    },
    serverStartDate: function () {
      return this.startDate.time
        ? this.convertApiTime(this.startDate.time)
        : null;
    },
    serverEndDate: function () {
      return this.endDate.time ? this.convertApiTime(this.endDate.time) : null;
    },
    filteredCategories() {
      const mappedSubs = this.selectedSubcategories.map((sub) => sub.id);
      return this.optionsCategories.filter((cat) => {
        if (mappedSubs.length) {
          const isCatSubs = cat.subcategories.some((sub) =>
            mappedSubs.includes(sub.id)
          );
          return isCatSubs;
        }
        return true;
      });
    },
    filteredSubcategories() {
      const mappedCats = this.selectedCategories.map((cat) => cat.id);
      return this.optionsCategories.filter((cat) => {
        if (this.selectedCategories && this.selectedCategories.length) {
          return mappedCats.includes(cat.id);
        }
        return true;
      });
    },
  },
  created() {
    this.setupDateTimePickerOpt();
  },
  mounted() {
    // Remove in future
    // this.localReports = this.getReports && this.getReports.filter(report => {
    //   // if (report.perm) {
    //   //   return this.$can(report.perm)
    //   // }
    //   return true
    // }) || []
    this.selectedStatus = this.optionsStatus[0];

    // non_empty param specifies that only sectors that have at least
    // one client will be returned
    this.fetchReportList();
    this.fetchSectors({
      includes: ["clients", "clients.locations"].join(","),
      forClient: 1,
    });
    this.isLoadingLocations = true;
    const clientIncludes = ["locations", "withArchived"].join();
    this.fetchClientsList({
      includes: clientIncludes,
      per_page: 999,
      // includes: [ 'locations.categories.subcategories' ].join(',')
    }).then(() => {
      this.fillOptionsClients();
      this.isLoadingLocations = false;
    });
  },
  methods: {
    ...mapActions({
      fetchSectors: "locationClientManagement/fetchSectors",
      // 'fetchSectors': 'reports/fetchSectors',
      fetchReportList: "reports/fetchReportList",
      fetchClientsList: "fetchClientsList",
      fetchClientCategories: "reports/fetchClientCategories",
      fetchPreviewReports: "reports/fetchPreviewReports",
      fetchDownloadReport: "reports/fetchDownloadReport",
      findAgencyWorker: "findAgencyWorker",
    }),
    asyncFindWorker: _.debounce(function (query) {
      if (query === "") return;
      const params = {
        ...{
          query,
          // gender: this.selectedGender && this.selectedGender.key
        },
        originallySelectedWorker: "",
      };
      this.findAgencyWorker(params);
    }, 400),
    setupDateTimePickerOpt() {
      this.dateTimeOption = Object.assign({}, calendarOptions.dateTimeOption);
      this.MultiShift = calendarOptions.MultiShift;
      this.dateTimeOption.type = "day";
      this.dateTimeOption.format = "DD/MM/YYYY";
    },
    fillOptionsClients() {
      this.optionsClients = this.clients.map((client) => {
        return {
          id: client.id,
          name: client.name,
        };
      });
    },

    isChangedSectorsList() {
      // @param values Holds all currently chosen sectors(or regions)
      // Every time `values` changes, we have to calculate clients
      // for those, and show them in the clients dropdown list
      this.resetClientCostCentre();
      this.selectedCategories = [];
      this.selectedSubcategories = [];
      // console.warn(values);
    },
    async isChangedClientsList(clientList) {
      if (clientList.length) {
        this.selectedClients = clientList;
        const params = {
          clients: clientList.map((client) => client.id),
          include: ["subcategories", "withArchived"].join(),
          // include: "subcategories",
        };

        // Filtering available wards based on client
        const clientSubIds = [];
        for (const client of clientList) {
          // Sub ids must be unique
          const clientLocationObj = this.filterLocation.find(
            (ccObj) => ccObj.client === client.name
          );
          if (clientLocationObj) {
            clientSubIds.push(...clientLocationObj.subs.map((sub) => sub.id));
          }
        }
        this.selectedLocation = this.selectedLocation.filter((cc) =>
          clientSubIds.includes(cc.id)
        );

        try {
          const res = await this.fetchClientCategories(params);
          this.optionsCategories = res.data.data;
        } catch (err) {
          console.log(err.message);
          this.optionsCategories = [];
          this.selectedCategories = [];
          this.selectedSubcategories = [];
        }
      } else {
        // Reset cc if no clients
        this.resetClientCostCentre();
        this.selectedCategories = [];
        this.selectedSubcategories = [];
        this.optionsCategories = [];
      }
    },
    resetClientCostCentre() {
      this.selectedClients = [];
      this.selectedLocation = [];
    },
    convertApiTime(d) {
      return moment(d, "DD-MM-YYYY").format("YYYY-MM-DD");
    },
    setReportObj(key, val, report) {
      const foundObj = this.getReports.find((el) => el.index === report.index);
      if (foundObj) {
        this.$set(foundObj, key, val);
      }
    },
    preview(report) {
      const getPermFromRep = (report) => this.reportsHardcodedPerm[report.name];

      if (
        this.isDateEmpty() &&
        !this.ignoredTimeIntervalKeys.includes(report.report.name)
      ) {
        this.$toasted.error("Please select time interval").goAway(1500);
        return;
      }

      if (
        !this.ignoredSubcatKeys.includes(report.report.name) &&
        this.selectedSubcategories &&
        this.selectedSubcategories.length
      ) {
        this.$toasted
          .error("This filter is not applicable for this report")
          .goAway(1500);
        return;
      }

      if (this.oneClientPerms.includes(getPermFromRep(report.report))) {
        if (this.selectedClients?.length > 1) {
          this.$toasted.error("Please select only one client").goAway(1500);
          return;
        } else if (this.selectedClients?.length !== 1) {
          this.$toasted.error("Please select one client").goAway(1500);
          return;
        }
      }

      if (this.oneWorkersPerms.includes(report.report.name)) {
        if (this.selectedWorkers?.length > 1) {
          this.$toasted.error("Please select only one worker").goAway(1500);
          return;
        } else if (this.selectedWorkers?.length !== 1) {
          this.$toasted.error("Please select one worker").goAway(1500);
          return;
        }
      }

      this.isPreviewingReport = true;
      const params = this.prepareParams();
      this.fetchPreviewReports({
        report,
        date_from: this.serverStartDate,
        date_to: this.serverEndDate,
        clients: params.clients,
        categories: params.categories,
        subcategories: params.subcategories,
        locations: params.locations,
        status: this.selectedStatus.id || null,
        temps: params.workers,
      })
        .then(() => {
          this.showModal(
            "report-preview",
            { previewData: this.getPreviewData },
            "Report Preview"
          );
          this.isPreviewingReport = false;
        })
        .catch(() => {
          this.$toasted.error("No data for preview").goAway(2000);
          this.isPreviewingReport = false;
        });
    },

    download(report) {
      const getPermFromRep = (report) => this.reportsHardcodedPerm[report.name];

      if (
        this.isDateEmpty() &&
        !this.ignoredTimeIntervalKeys.includes(report.report.name)
      ) {
        this.$toasted.error("Please select time interval").goAway(1500);
        return;
      }

      if (
        !this.ignoredSubcatKeys.includes(report.report.name) &&
        this.selectedSubcategories &&
        this.selectedSubcategories.length
      ) {
        this.$toasted
          .error("This filter is not applicable for this report")
          .goAway(1500);
        return;
      }

      if (
        !this.reportCanUseSelectWorkerFilter.includes(report.report.name) &&
        this.selectedWorkers?.length > 0
      ) {
        this.$toasted
          .error("Filter for 'Workers' is not applicable for this report")
          .goAway(1500);
        return;
      }

      if (this.oneClientPerms.includes(getPermFromRep(report.report))) {
        if (this.selectedClients?.length > 1) {
          this.$toasted.error("Please select only one client").goAway(1500);
          return;
        } else if (this.selectedClients?.length !== 1) {
          this.$toasted.error("Please select one client").goAway(1500);
          return;
        }
      }

      if (this.oneWorkersPerms.includes(report.report.name)) {
        if (this.selectedWorkers?.length > 1) {
          this.$toasted.error("Please select only one worker").goAway(1500);
          return;
        } else if (this.selectedWorkers?.length !== 1) {
          this.$toasted.error("Please select one worker").goAway(1500);
          return;
        }
      }

      this.setReportObj("isLoading", true, report.report);
      console.warn(report.report);

      const params = this.prepareParams();
      this.fetchDownloadReport({
        report,
        date_from: this.serverStartDate,
        date_to: this.serverEndDate,
        clients: params.clients,
        categories: params.categories,
        subcategories: params.subcategories,
        temps: params.workers,
        locations: params.locations,
        status: this.selectedStatus.id || null,
      })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const fileName = report.report.label + ".xlsx";

          const a = document.createElement("a");
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(url);
          this.setReportObj("isLoading", false, report.report);
        })
        .catch(() => {
          // Handle server download errors
          this.$toasted.error("No data for download").goAway(2000);
          this.setReportObj("isLoading", false, report.report);
        });
    },

    showModal(modal, modalData = {}, modalTitle = "Modal title") {
      this.inmodal = modal;
      this.modalData = modalData;
      this.modalTitle = modalTitle;
      this.modalVisible = true;
    },

    getDates() {
      let from = "";
      let to = "";
      if (this.selectedInterval === "This week") {
        from = moment().startOf("isoWeek").toISOString().substring(0, 10);
        to = moment().endOf("isoWeek").toISOString().substring(0, 10);
        this.startDate.time = moment(from, "YYYY-MM-DD").format("DD/MM/YYYY");
        this.endDate.time = moment(to, "YYYY-MM-DD").format("DD/MM/YYYY");
      } else if (this.selectedInterval === "Last week") {
        from = moment()
          .subtract(7, "days")
          .startOf("isoWeek")
          .toISOString()
          .substring(0, 10);
        to = moment()
          .subtract(7, "days")
          .endOf("isoWeek")
          .toISOString()
          .substring(0, 10);
        this.startDate.time = moment(from, "YYYY-MM-DD").format("DD/MM/YYYY");
        this.endDate.time = moment(to, "YYYY-MM-DD").format("DD/MM/YYYY");
      } else {
        this.startDate.time = "";
        this.endDate.time = "";
      }
    },

    isDateEmpty() {
      this.isEmpty = this.startDate.time === "" || this.endDate.time === "";
      return this.isEmpty;
    },

    getParam(filter) {
      if (filter) {
        return filter.map((value) => {
          return {
            id: value.id,
          };
        });
      } else {
        return [];
      }
    },

    prepareParams() {
      // Make logic for when Sector/regions is selected
      // then send complete list of clients for them
      // Otherwise, send just the selected ones!
      let clients = null;
      if (this.selectedClients.length) {
        clients = this.selectedClients;
      } else if (this.selectedSectors.length) {
        // Collect clients for all selected Sectors
        clients = this.filterClients; // Using the fact, that this dropdown already has all logic applied
      }
      const paramObj = {
        clients: this.getParam(clients),
        categories: this.getParam(this.selectedCategories),
        subcategories: this.getParam(this.selectedSubcategories),
        workers: this.getParam(this.selectedWorkers),
      };

      if (this.selectedLocation.length) {
        paramObj.locations = this.getParam(this.selectedLocation);
      }

      return paramObj;
    },
  },
};
</script>

<style lang="scss" scoped>
$color--light-gray: #dde6eb;
$color--light-blue: #8399a6;
$color--border: #9bb4c1;

.divider {
  padding: 25px 0 45px 0;
  border-top: 2px solid $color--light-gray;
  min-height: 50px;

  &.below {
    .label {
      margin: 0 5px;
    }
  }
}

.input {
  border: 1px solid $color--light-gray;
  height: 37px;
  width: 200px;

  &::placeholder {
    color: $color--light-blue;
    font-size: 15px;
  }
}

.control {
  i {
    top: 10px;
    position: absolute;
    right: 20px;
    font-size: 14px;
    color: $color--light-blue;
  }
}

.filter {
  .control {
    min-width: 250px;
    max-width: 500px;

    i {
      position: absolute;
      right: 0;
      top: 0;
    }

    .label {
      margin-left: 5px;
    }

    .status,
    .subcat {
      display: flex;
      align-items: center;

      .info {
        border-radius: 50%;
        border: 1px solid silver;
        margin-left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 27px;
        min-height: 27px;
        width: 27px;
        height: 27px;

        .fa {
          color: #7c91ae;
        }
      }
    }
  }
}

.flex {
  display: flex;
  align-items: center;

  .date-dropdown {
    width: 180px;
  }
}

.field {
  display: flex;
  align-items: center;
  margin-bottom: 0;

  label {
    margin: 0 1em;
  }
}

.nivelate {
  position: relative;
  top: 5px;
  margin-right: 10px;
}

.interval {
  .field {
    float: right;
  }
}

.label {
  color: #7c91ae;
}

.help {
  .is-danger {
    position: relative;
    left: 150px;
    margin-bottom: 20px;
  }
}

.error {
  width: 100%;
  text-align: right;

  p {
    margin-right: 150px;
    margin-bottom: 10px;
  }
}

.field {
  .label {
    position: relative;
    top: -5px;
  }
}

.columns {
  margin-top: 0;

  .column {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .right-side {
    display: flex;
    align-items: flex-start;

    .column {
      align-items: center;
      flex-direction: row;
      gap: 10px;

      label {
        margin-bottom: 0;
      }
    }
  }
}

.divider {
  margin-top: 40px;
  padding: 10px 0 20px 0;
  border-top: 2px solid $color--light-gray;
  border-bottom: 2px solid $color--light-gray;
  min-height: 150px;

  .breaker {
    position: absolute;
    width: 1px;
    height: 100px;
    background-color: $color--light-gray;
    right: 0;
    top: 35px;
  }
}

@media (max-width: 768px) {
  .breaker {
    display: none;
  }
}
</style>
<style lang="scss">
.reports-modal {
  .modal-content {
    width: 85%;
    overflow: hidden;
  }

  .panel {
    width: 100%;

    .panel-block {
      overflow: hidden;
    }
  }
}

.report-status-tooltip {
  // border-radius: 10px;
  border: 1px solid transparent;
  color: white;
  font-weight: 600;
}
</style>
