import { render, staticRenderFns } from "./PayBillAnnualLeaveTable.vue?vue&type=template&id=47e0bf46&scoped=true&lang=pug"
import script from "./PayBillAnnualLeaveTable.vue?vue&type=script&setup=true&lang=ts"
export * from "./PayBillAnnualLeaveTable.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./PayBillAnnualLeaveTable.vue?vue&type=style&index=0&id=47e0bf46&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.10.0_prettier@3.2.5_pug@3.0.2_underscore@1.13.6_vue-template-_6c5xy3ku26i64jowneuu67eca4/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47e0bf46",
  null
  
)

export default component.exports