var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"table-wrap"},[_c('table',{staticClass:"table sticky"},[_c('thead',[_c('tr',[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('th',{staticClass:"is-narrow"},[_c('div',{staticClass:"total-value"},[_c('span',[_vm._v("Total Monetary Value")]),(_setup.totalValueSum)?_c('input',{staticClass:"input",attrs:{"disabled":""},domProps:{"value":_setup.totalValueSum}}):_vm._e()])]),(_setup.hasBalanceField)?_c('th',{staticClass:"is-narrow"},[_c('span',[_vm._v("Balance")])]):_vm._e()])]),_c('tbody',[_vm._l((_setup.filteredHolidayRequestList),function(item,index){return _c('tr',{key:index},[_c('td',[_c('span',[_vm._v(_vm._s(item.ubt_name))])]),_c('td',[_c('span',[_vm._v(_vm._s(_setup.formatDateIsoToLoc(item.usb_created)))])]),_c('td',[_c('span',[_vm._v(_vm._s(_setup.formatDateIsoToLoc(item.payment_date)))])]),_c('td',[_c('span',[_vm._v(_vm._s(item.description))])]),_c('td',[_c('span',[_vm._v(_vm._s(item.month))])]),_c('td',{staticStyle:{"text-align":"right"}},[_c('span',[_vm._v(_vm._s(item.worked_hours))])]),_c('td',{staticStyle:{"text-align":"right"}},[_c('span',[_vm._v(_vm._s(item.hours))])]),_c('td',{staticStyle:{"text-align":"right"}},[('value' in item)?_c('span',[_vm._v(_vm._s(_setup.getTotalMonetaryVal(item)))]):_vm._e()]),(_setup.hasBalanceField)?_c('td',{staticStyle:{"text-align":"right"}},[_c('span',[_vm._v(_vm._s(_setup.getBalanceParsed(item)))])]):_vm._e()])}),(_setup.filteredHolidayRequestList.length === 0)?_c('tr',{staticClass:"empty"},[_c('td',{staticStyle:{"text-align":"center"},attrs:{"colspan":"100"}},[_vm._v("No data in table")])]):_vm._e()],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('th',{staticClass:"is-narrow"},[_c('span',[_vm._v("Transaction Type")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('th',{staticClass:"is-narrow"},[_c('span',[_vm._v("Date")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('th',[_c('span',[_vm._v("Payment date")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('th',[_c('span',[_vm._v("Description")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('th',{staticClass:"is-narrow"},[_c('span',[_vm._v("Month")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('th',{staticClass:"is-narrow"},[_c('span',[_vm._v("Hours Worked")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('th',{staticClass:"is-narrow"},[_c('span',[_vm._v("AL Entitlment")])])
}]

export { render, staticRenderFns }